.Container {
  position: relative;
  flex: 1;
  display: flex;
  max-width: 634px;
  height: 452px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 50px auto;
  outline: none;
  cursor: pointer;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  overflow: hidden;
}

.Container::after {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: 1px dashed #D3D8DB;
  content: '';
  display: block;
}

.Container img {
  max-width: 100%;
  height: auto;
}

.ContainerInner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.anchor {
  color: #027AFF;
  text-decoration: underline;
}