@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);

/* Inputs */
.sr-input,
.sr-select{
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #32325d;
}
.sr-input:focus,
.StripeElement--focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
  z-index: 9;
}
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #424F61;
  background: #F3F4F6;
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
}

* {
  box-sizing: border-box;
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

.appHeader {
  height: 53px;
  padding-top: 14px;
  border-bottom: 1px solid #E8ECEE;
  background: #FFFFFF;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.containerSmall {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.customerForm {
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  padding: 20px;
  width: 634px;
  margin: 50px auto;
}

.steps {
  height: 66px;
  margin: 0;
  margin-bottom: 50px;
  font-size: 16px;
  border-bottom: 1px solid #E8ECEE;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  background: #FFFFFF;
}

.step {
  margin: 0 10px;
}

.selected {
  font-weight: 700;
  margin: 0 10px;
}

.button {
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  background: #027AFF;
  padding: 10px 20px;
  outline: 0;
  border: none;
}

.fieldRow {
  display: flex;
  margin-left: -20px;
  margin-bottom: 20px;
}
.Container {
  position: relative;
  flex: 1 1;
  display: flex;
  max-width: 634px;
  height: 452px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 50px auto;
  outline: none;
  cursor: pointer;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  overflow: hidden;
}

.Container::after {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: 1px dashed #D3D8DB;
  content: '';
  display: block;
}

.Container img {
  max-width: 100%;
  height: auto;
}

.ContainerInner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.anchor {
  color: #027AFF;
  text-decoration: underline;
}
.ErrorMessages {
  color: #EA6553;
}

.ErrorMessages p {
  margin: 0;
}
.TextInput, .sr-input {
  box-sizing: border-box;
  width: 100%;
  margin: 4px 0;
  padding: 12px 20px;
  font-size: 14px;
  height: 40px;
  border: 2px solid #D3D8DB;
  background-color: #FFFFFF;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

.FieldGroup {
  width: 100%;
  padding-left: 20px;
}

.Label {
    margin: 0;
}
