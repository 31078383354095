@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #424F61;
  background: #F3F4F6;
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
}

* {
  box-sizing: border-box;
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

.appHeader {
  height: 53px;
  padding-top: 14px;
  border-bottom: 1px solid #E8ECEE;
  background: #FFFFFF;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.containerSmall {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.customerForm {
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  padding: 20px;
  width: 634px;
  margin: 50px auto;
}

.steps {
  height: 66px;
  margin: 0;
  margin-bottom: 50px;
  font-size: 16px;
  border-bottom: 1px solid #E8ECEE;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  background: #FFFFFF;
}

.step {
  margin: 0 10px;
}

.selected {
  font-weight: 700;
  margin: 0 10px;
}

.button {
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  background: #027AFF;
  padding: 10px 20px;
  outline: 0;
  border: none;
}

.fieldRow {
  display: flex;
  margin-left: -20px;
  margin-bottom: 20px;
}