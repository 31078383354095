.TextInput, .sr-input {
  box-sizing: border-box;
  width: 100%;
  margin: 4px 0;
  padding: 12px 20px;
  font-size: 14px;
  height: 40px;
  border: 2px solid #D3D8DB;
  background-color: #FFFFFF;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

.FieldGroup {
  width: 100%;
  padding-left: 20px;
}

.Label {
    margin: 0;
}